export const featureToggles = {
  allowDarkMode: false,
  allowDeleteRecords: false,
  componentZoo: false, // Design system; a place to test components in a controlled environment
  inlineDevParams: false, // Additional controls to play with components within the app
  newNavigationMenu: false, // turn on when menu is done
  ddShowSAQRequestCTA: false, // Show a card to initiate an SAQ request from the assessment results in the Due Diligence screen
  // TODO: remove this feature toggle
  ddDontReallySendSaq: true, // After entering the recipent's details, pretend to send the SAQ request but don't (because we can't provide a golden company, and the API will soon not require one)
  'SAQ: Engagement (formerly Questionnaires)': false,
  'Identify: use Identify screen instead of Companies': true,
  'Identify: date filter, page title': false,
  'Identify: extended filters': false,
  'Reports: PDF Download': false,
};

export type FeatureToggles = typeof featureToggles;
